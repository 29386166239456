import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import Dropzone from 'react-dropzone-uploader';

import AppContainer from '../../../components/app-container';
import AppTable from '../../../components/AppTable';
import EmojiButton from '../../../components/EmojiButton';

import 'react-dropzone-uploader/dist/styles.css'
import styles from './list.module.css';

import { uploadFile } from '../../../transport/api';
import { makeTitle } from '../../../utils';
import Select from '../../../components/Select';
import PaymentManagementModal from './PaymentManagementModal';
import ExportReporModal from './ExportReporModal';
import AddTitle from '../../../components/AddTitle';
import EditModal from '../../../components/EditModal';
import NewGoods from './NewGoods';
import Input from '../../../components/input';

const Goods = () => {
  makeTitle('Проекты')
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [loading, toggleLoading] = useState(false);
  const [isExcelModalOpen, toggleExcelModalOpen] = useState(false);
  const [isPaymentsModalOpen, togglePaymentsModalOpen] = useState(false);
  const [sellerId, setSellerId] = useState();
  const [reportDate, setReportDate] = useState();
  const [isFileAttached, toggleIsFileAttached] = useState(false);
  const [isUploading, toggleIsUploading] = useState(false);
  const [isReportDialogOpen, toggleReportDialogOpen] = useState(false);
  const [isNewGoods, toggleNewGoods] = useState(false)
  const [showHidden, toggleShowHidden] = useState(false)

  const handleChangeStatus = ({ meta }, status) => {
    if (status === 'done') {
      toggleIsFileAttached(true);
    } else if (status === 'removed') {
      toggleIsFileAttached(false);
    }
  }

  const handleSubmit = async (files, allFiles) => {
    if (!sellerId) {
      return toast.error('Заполните поле "Продавец"');
    }
    const file = files[0].file;
    const data = new FormData();
    data.append('file', file);
    data.append('report_date', reportDate);

    toggleIsUploading(true);
    uploadFile(`/goods/${sellerId}/upload`, data)
      .then(() => {
        toast.success('Файл успешно загружен');
        allFiles[0].remove();
        toggleExcelModalOpen(false);
        toggleIsUploading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        toggleIsUploading(false);
      });
  }

  return (
    <AppContainer
      title={
        <div className={styles['title-container']}>
          <AddTitle text='Проекты' onAddClick={() => toggleNewGoods(true)} />
          <EmojiButton
            onClick={() => toggleExcelModalOpen(true)}
            disabled={loading}
            className={styles['emoji-button']}
            title="Импорт из экселя"
            emoji={
              <img
                className={styles['excel-logo']}
                src="/icons/excel.png"
                alt="excel icon"
              />
            }
          />
          <EmojiButton
            onClick={() => togglePaymentsModalOpen(true)}
            disabled={loading}
            className={styles['emoji-button']}
            title="Управление выплатами"
            emoji="💰"
          />
          <EmojiButton
            onClick={() => toggleReportDialogOpen(true)}
            disabled={loading}
            className={styles['emoji-button']}
            title="Выгрузка отчёта"
            emoji="📑"
          />
        </div>
      }
      filters={[
        <EmojiButton
          emoji={showHidden ? '🐵' : '🙈'}
          title="Показать скрытые артикулы"
          onClick={() => {
            toggleShowHidden(!showHidden)
          }}
        />,
        <Select
          value={name}
          onChange={(value) => setName(() => value)}
          resourcePath="/goods/value-list"
          optionsColumns={{ value: 'name', label: 'name' }}
          label="Название"
        />
      ]}
    >
      <AppTable
        columns={[
          { name: 'media_url', title: '', type: 'image', canSort: false },
          { name: 'name', title: 'Название' },
          {
            name: 'cash_balance',
            title: 'Баланс',
            type: 'number'
          },
          {
            name: 'goods_balance',
            title: 'Товарный баланс',
            type: 'number'
          },
          {
            name: 'profit',
            title: 'Прибыль',
            type: 'number',
          },
          {
            name: 'revenue',
            title: 'Выручка',
            type: 'number'
          },
          { name: 'sales', title: 'Продажи', type: 'number' },
          { name: 'tax', title: 'Неоплаченный налог', type: 'number' },
          {
            name: 'is_used_in_shared',
            title: 'Участвует в общих операциях?',
            type: 'bool',
          },
        ]}
        onClick={(row) => navigate(`/goods/${row.id}`)}
        resourcePath="/goods"
        filters={[
          { fieldName: 'name', value: name },
          { fieldName: 'is_dead_showing', value: showHidden },
        ]}
        onLoad={() => toggleLoading(false)}
        defaultSorting={[{ id: 'cash_balance', desc: true }]}
      />
      <ReactModal
        className={styles['modal-container']}
        overlayClassName={styles['modal-overlay']}
        isOpen={isExcelModalOpen}
        ariaHideApp={false}
        onRequestClose={() => toggleExcelModalOpen(isUploading || false)}
        style={{
          content: {
            height: isFileAttached ? '22em' : '18em'
          }
        }}
      >
        <div className={styles['modal-select']}>
          <Select
            value={sellerId}
            onChange={(value) => setSellerId(() => value)}
            resourcePath="/sellers"
            optionsColumns={{ value: 'id', label: 'name' }}
            label="Продавец"
            disabled={isUploading}
          />
          <Input
            type="date"
            value={reportDate}
            onChange={(e) => setReportDate(e.target.value)}
            label="Дата отчёта"
          />
        </div>
        <Dropzone
          inputContent="Выберите или перетащите файл недельного отчёта"
          maxFiles={1}
          multiple={false}
          styles={{
            dropzone: { overflow: 'auto', borderColor: 'var(--modal-background)', borderRadius: 7 },
            inputLabel: { color: 'var(--primary)' },
            submitButton: {
              font: 'inherit',
              background: '#605BFF',
              color: '#FFFFFF',
              border: 'none',
              borderRadius: '10px',
              width: '100%',
              padding: '.7em',
              display: sellerId && isFileAttached && reportDate ? 'inherit' : 'none'
            }
          }}
          onSubmit={handleSubmit}
          submitButtonContent="Отправить"
          disabled={isUploading}
          onChangeStatus={handleChangeStatus}
        />
      </ReactModal>
      <PaymentManagementModal isOpen={isPaymentsModalOpen} close={() => togglePaymentsModalOpen(false)} />
      <ExportReporModal isOpen={isReportDialogOpen} close={() => toggleReportDialogOpen(false)} />
      <EditModal close={() => toggleNewGoods(false)} isOpen={isNewGoods}>
        <NewGoods />
      </EditModal>
    </AppContainer>
  );
};

export default Goods;
