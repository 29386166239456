import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { requestTable } from '../../transport/api';

const Select = ({
  key,
  disabled,
  label,
  onChange,
  customOptions,
  value,
  optionsColumns,
  resourcePath,
  forceUpdate = false,
  filters = [],
  sorting = [],
  isMulti = false
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, toggleLoading] = useState(false);
  const [forceLoad, toggleforceLoad] = useState(forceUpdate);

  const loadOptions = () => {
    toggleLoading(true);
    if (customOptions) {
      setOptions(() => customOptions);
    }
    if (resourcePath && optionsColumns) {
      requestTable(resourcePath, filters, sorting, 1, 300).then((data) => {
        setOptions(() => data?.list.map((item) => ({ value: item[optionsColumns.value], label: item[optionsColumns.label] })));
      })
    }
    toggleLoading(false);
  }

  if (forceLoad) {
    loadOptions();
    toggleforceLoad(false);
  }

  return ( 
    <ReactSelect
      key={key}
      styles={{
        control: (base) => ({
          ...base, 
          // marginTop: '.5em',
          minWidth: '10em',
          border: 'none',
          borderRadius: '10px',
          padding: '.25em',
          boxShadow: 'none',
          height: '3em',
          background: disabled ? 'var(--control-disabled)' : 'var(--control-background)',
          color: 'var(--text-color)',
        }),
        menu: (base) => ({
          ...base,
          width: 'max-content',
          minWidth: '100%',
          maxHeight: '20em',
          overflowY: 'scroll',
          position: 'absolute',
          right: 0,
          background: 'var(--control-background)',
        }),
        indicatorSeparator: (base) => ({ display: 'none' }),
        menuList: (base) => ({
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          background: 'var(--control-background)',
        }),
        singleValue: (base) => ({
          ...base,
          color: 'var(--text-color)',
        }),
        clearIndicator: (base) => ({ ...base, padding: '8px 8px 8px 0' }),
        dropdownIndicator: (base, state) => ({ ...base, display: state.hasValue ? 'none' : undefined, maxHeight: '36px' }),
        placeholder: (base) => ({
          ...base,
          color: 'var(--disabled-text-color)',
        })
      }}
      captureMenuScroll={false}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      placeholder={label} 
      options={options}
      isDisabled={disabled}
      isClearable
      isLoading={!disabled && isLoading}
      onFocus={loadOptions}
      menuPlacement="auto"
      menuPosition="fixed"
      noOptionsMessage={() => 'Значение не найдено'}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'var(--modal-background)',
          primary: 'var(--primary)',
          neutral10: 'var(--control-background)',
          primary50: 'var(--background)',
        },
      })}

      value={Array.isArray(value) ? options.filter((option) => value.includes(option.value)) : options.find((option) => String(option.value) === String(value))}
      onChange={(option) => {
        if (isMulti) {
          onChange(option.map(o => o?.value));
        } else {
          onChange(option?.value);
        }
      }}
    />
  );
}

export default Select;
